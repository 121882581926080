<template>
  <transition name="fade" mode="in-out">
    <div v-show="active">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
import eventBus from "@/js/util/eventBus"
export default {
  props: {
    taxonomy: {
      type: String,
    },
    initial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    }
  },
  methods: {
    toggleFilter(filter) {
      console.log('hi from ' + this.taxonomy)
      console.log('heard from ' + filter)
      if (this.taxonomy == filter) {
        console.log('inside if')
        this.active = true
      } else {
        this.active = false
      }
    }
  },
  mounted() {
    if (this.initial) this.active = true
    eventBus.$on('toggle:filter', this.toggleFilter)
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  position: absolute;
  transition: all 0.25s ease-in;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  position: absolute;
  transform: translateX(25px);
}
</style>
