<template>
  <div />
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSPlugin } from 'gsap/CSSPlugin'

gsap.registerPlugin(ScrollTrigger, CSSPlugin)

export default {
  data () {
    return {
      facetLoaded: 0
    }
  },
  mounted () {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')

    if (!mediaQuery.matches) {
      this.fadeInUp()
      this.fadeIn()

      // listen for the Load More trigger on work page
      document.addEventListener('facetwp-loaded', () => {
        // prevent it from running before the first actual load
        if (this.facetLoaded++ >= 1) {
          this.fadeInUp()
        }
      })
    }
    this.drawUnderline()
    this.hideOverFooter()
  },
  methods: {
    fadeInUp () {
      gsap.utils.toArray('.global__fade-in-up:not(.global__loaded)').forEach(function (item) {
        const fadeInUp = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            // toggleActions: 'restart none none reverse',
            start: 'top 95%'
          }
        })

        fadeInUp.from(item, {
          ease: 'expo.out',
          duration: 1.5,
          opacity: 0,
          y: '15%'
        })
      })

      document.querySelectorAll('.global__fade-in-up').forEach(function (el) {
        el.classList.add('global__loaded')
      })
    },
    drawUnderline () {
      gsap.utils.toArray('.global__draw-underline').forEach(function (item) {
        gsap.timeline({
          scrollTrigger: {
            trigger: item,
            once: true,
            toggleClass: 'active',
            start: 'top 75%'
          }
        })
      })
    },
    bounce () {
      gsap.utils.toArray('.global__bounce').forEach(function (item) {
        const bounce = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: 'top 95%'
          }
        })
      })
    },
    hideOverFooter () {
      gsap.utils.toArray('.global__hide-over-footer').forEach(function (item) {
        gsap.timeline({
          scrollTrigger: {
            trigger: item,
            toggleClass: 'hidden',
            start: 'top bottom'
          }
        })
      })
    },

    fadeIn () {
      gsap.utils.toArray('.global__fade-in:not(.global__loaded)').forEach(function (item) {
        gsap.set(item, {
          opacity: 0,
          duration: 1,
          ease: 'power3.out',
          overwrite: 'auto'
        })
        if (window.innerWidth < 768) {
          ScrollTrigger.create({
            trigger: item,
            toggleActions: 'restart none none reverse',
            start: 'top 30%',

            end: 'bottom 15%',
            id: 'out',
            onEnter: () => gsap.to(item, {
              opacity: 1,
              stagger: 0.2
            }),
            onLeave: () => gsap.to(item, {
              opacity: 0,
              stagger: 0.5
            }),
            onEnterBack: () => gsap.to(item, {
              opacity: 1,
              stagger: -0.2
            }),
            onLeaveBack: () => gsap.to(item, {
              opacity: 0,
              stagger: -0.5
            })
          })
        } else {
          ScrollTrigger.create({
            trigger: item,
            toggleActions: 'restart none none reverse',
            start: 'top 75%',

            end: 'bottom 15%',
            id: 'out',
            onEnter: () => gsap.to(item, {
              opacity: 1,
              stagger: 0.2
            }),
            onLeave: () => gsap.to(item, {
              opacity: 0,
              stagger: 0.5
            }),
            onEnterBack: () => gsap.to(item, {
              opacity: 1,
              stagger: -0.2
            }),
            onLeaveBack: () => gsap.to(item, {
              opacity: 0,
              stagger: -0.5
            })
          })
        }
      })

      document.querySelectorAll('.global__fade-in').forEach(function (el) {
        el.classList.add('global__loaded')
      })
    }
  }
}
</script>

<style lang="scss">
@keyframes pt_wink {
  0% {
    transform: scale(.5);
  }
  10% {
    transform: scale(.85);
    opacity: 1;
  }
  25% {
    transform: scale(1.25);
    opacity: 1;
  }
  75% {
    transform: scale(.85);
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.global__draw-underline:not(.pressure-test) {
  display: inline;
  transition: background-size 0.5s ease-in-out;
  transition-delay: 1.5s;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 0 0.085em;

  svg {
    opacity: 0;
  }
  &.active {
    background-size: 100% 0.085em;

    svg {
      transform-origin: center;
      animation: pt_wink .5s forwards ease-in-out;
      animation-delay: 2s;
    }
  }
}

.global__draw-underline.pressure-test {
  .pressure-test_second,
  .pressure-test_over,
  .pressure-test_first {
    display: inline;
    transition: background-size 0.5s ease-in-out;
    transition-delay: 1.5s;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 0 0.085em;

    .pressure-test_wink {
      opacity: 0;
    }
  }
  &.active {
    .pressure-test_second,
    .pressure-test_over,
    .pressure-test_first {
      background-size: 100% 0.085em;

      .pressure-test_wink {
        transform-origin: center;
        animation: pt_wink .5s forwards ease-in-out;
        animation-delay: 2s;
      }
    }
  }
}

// don't want footer pressure test to animate
.footer {
  .global__draw-underline {
    .pressure-test_second,
    .pressure-test_over,
    .pressure-test_first {
      background-size: 100% 0.085em;
    }

    .pressure-test_wink {
      opacity: 1 !important;
      animation: unset !important;
    }
  }
}
</style>
