<template>
  <button
    :class="{ expanded: expanded }"
    @click="toggleExpanded"
    :aria-expanded="toString(expanded)"
  >
    <slot></slot>
    <span
      class="header__toggle-label"
      v-html="expanded ? 'Close' : 'Menu'"
    ></span>
  </button>
</template>

<script>
import eventBus from "@/js/util/eventBus";
export default {
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      eventBus.$emit("topnav:toggle", this.expanded);
    },
    handleEsc(e) {
      if ((e.keyCode === 27) & this.expanded) {
        this.toggleExpanded();
      }
    },
  },
  mounted() {
    window.addEventListener("keyup", this.handleEsc);
  },
};
</script>
