<template>
  <div class="work-gallery__col">
    <div
      class="work-gallery__row"
      v-for="(row, index) in data"
      :key="index"
      :class="`work-gallery__row-${row.row_style}`"
    >
      <img
        :src="row.image_1.url"
        :alt="row.image_1.alt"
        @click="openToModal(index, 0)"
      />

      <img
        v-if="row.image_2"
        :src="row.image_2.url"
        :alt="row.image_2.alt"
        @click="openToModal(index, 1)"
      />
    </div>
  </div>
</template>

<script>
import eventBus from "@/js/util/eventBus";

export default {
  props: {
    data: {
      type: Array,
    },
  },
  methods: {
    openToModal(row, image) {
      if (window.innerWidth >= 960) {
        eventBus.$emit("galleryModal:open", { row: row, image: image });
      }
    },
  },
};
</script>

