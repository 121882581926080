<template>
  <form
    :id="id"
    :action="redirectPage"
    method="post"
  >
    <div class="form-columns">
      <div
        id="name-field-wrapper"
        class="field-wrapper"
      >
        <label
          id="label-name"
          class="text__p-reg"
          for="contactname"
        >
          <input
            id="input-name"
            type="text"
            class="input"
            name="contactname"
            required
          >
          <span>Name</span>
          <div class="required">
            This field is required
          </div>
        </label>
      </div>
      <div
        id="country-field-wrapper"
        class="field-wrapper"
      >
        <label
          id="label-country"
          class="text__p-reg"
          for="input-country"
        >
          <input
            id="input-country"
            type="text"
            class="input"
            name="country"
          >
          <span>Country</span>
        </label>
      </div>
    </div>
    <div class="form-columns">
      <div
        id="email-field-wrapper"
        class="field-wrapper"
      >
        <label
          id="label-email"
          class=" text__p-reg"
          for="input-email"
        >
          <input
            id="input-email"
            class="input"
            type="email"
            name="email"
            required
          >
          <span>Email Address</span>
          <div class="required">
            Email is required and must be formatted correctly
          </div>
        </label>
      </div>
    </div>
    <div class="form-columns">
      <div
        id="company-field-wrapper"
        class="field-wrapper"
      >
        <label
          id="label-company"
          class="text__p-reg"
          for="input-company"
        >
          <input
            id="input-company"
            type="text"
            class="input"
            name="company"
            required
          >
          <span>Company Name</span>
          <div class="required">
            Company name is required
          </div>
        </label>
      </div>
      <div
        id="job-field-wrapper"
        class="field-wrapper"
      >
        <label
          id="label-job"
          class="text__p-reg"
          for="input-job"
        >
          <input
            id="input-job"
            type="text"
            class="input"
            name="job-title"
          >
          <span>Job Title </span>
        </label>
      </div>
    </div>
    <div class="form-columns">
      <div
        id="job-field-wrapper"
        class="field-wrapper"
      >
        <label
          id="label-message"
          class="text__p-reg"
          for="input-job"
        >
          <div>Message</div>
          <input
            id="input-message"
            type="textarea"
            class="input message"
            name="message"
            rows="4"
          >
          <span style="width=0;height=0;" />
        </label>
      </div>
    </div>
    <div class="submit-wrapper">
      <label
        for="hs-submit"
        class="button"
      >
        <input
          type="submit"
          value=""
          class="submit-button"
        >
        <svg
          width="22"
          height="18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.884 8.967c-.003-.044-.005-.088-.014-.132a1.051 1.051 0 00-.048-.164l-.007-.024a1.02 1.02 0 00-.244-.355L14.3 1.02a1.021 1.021 0 10-1.445 1.445l5.544 5.543H1.222a1.022 1.022 0 100 2.044h17.25l-5.618 5.618a1.022 1.022 0 001.445 1.445l7.292-7.292c.208-.208.305-.483.296-.756 0-.012.003-.024.003-.035V9.03c0-.021-.005-.041-.006-.063"
            fill="#D60000"
            fill-rule="evenodd"
          />
        </svg>
        <div> Submit</div>
      </label>
    </div>
  </form>
</template>

<script>
/* eslint-disable no-useless-escape */

export default {
  name: 'HsForm',
  props: {
    id: {
      type: String,
      default: 'contact-form'
    },
    redirectPage: {
      type: String,
      default: '160over90.com/higher-education-contact-form-success/'
    }
  },

  created () {
    var scripts = [
      '//js.hs-scripts.com/7331474.js'
    ]
    scripts.forEach(script => {
      let tag = document.createElement('script')
      tag.setAttribute('src', script)
      document.head.appendChild(tag)
    })
  },

  mounted () {
    const inputs = this.$el.getElementsByClassName('input')

    Array.from(inputs).forEach((input) => {
      input.addEventListener('focus', () => {
        input.parentElement.classList.add('focused')
      })
      input.addEventListener('blur', () => {
        input.parentElement.classList.remove('focused')

        // eslint-disable-next-line no-mixed-operators
        if (input.type === 'email') {
          if (!this.validateEmail(input.value) || input.value === '') {
            input.parentElement.classList.add('invalid')
          } else {
            input.parentElement.classList.remove('invalid')
          }
          if (input.value !== '') {
            input.nextElementSibling.classList.add('active')
          } else {
            input.nextElementSibling.classList.remove('active')
          }
        } else {
          if (input.value !== '') {
            input.nextElementSibling.classList.add('active')
          } else {
            input.nextElementSibling.classList.remove('active')
          }
          if (input.required && input.value === '') {
            input.parentElement.classList.add('invalid')
          } else {
            input.parentElement.classList.remove('invalid')
          }
        }
      })
    })
  },
  methods: {
    validateEmail (email) {
      var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (email.match(mailformat)) { return true } else { return false }
    }
  }
}
</script>
