<template>
  <div>
    Test2
  </div>
</template>

<script>
export default {
  name: 'ExampleComponent',
  // Component Variables
  props: {
    exampleProp: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      exampleData: 'defaultValue'
    }
  },
  computed: {
    exampleComputed () {
      let date = new Date()
      return date
    }
  },
  // Component lifecycle hooks
  beforeCreate () {},
  created () {
    // console.log('created')
    // console.log(this.exampleComputed)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  // Component Functions
  methods: {
    exampleMethod () {}
  }
}
</script>

<style lang='scss' scoped>
</style>
