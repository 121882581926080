<template>
  <header :class="{ scrolled: !atTop }">
    <slot />
  </header>
</template>

<script>
import eventBus from "@/js/util/eventBus";

export default {
  data() {
    return {
      atTop: true,
      scrollPos: window.scrollY,
      scrolled: false,
      scrollable: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    eventBus.$on("nav:noscroll", this.toggleScrollable); // use this event if there is a pinned animation at the top of the page
  },
  destroyed() {
    window.removeEventListener("scroll");
  },
  methods: {
    handleScroll() {
      if (this.scrollable) {
        var newScroll = window.pageYOffset || this.$el.scrollTop;
        // console.log('scroll: ' + this.scrollPos + ',' + newScroll)
        if (window.pageYOffset < 100 || newScroll < this.scrollPos) {
          this.$el.classList.remove("hidden");
        } else {
          this.atTop = false;
          this.$el.classList.add("hidden");
        }

        this.scrollPos = newScroll;
        // console.log('scroll2: ' + this.scrollPos + ',' + newScroll)
      }
    },

    toggleScrollable(scrollable) {
      this.scrollable = scrollable;
      this.$el.classList.remove("hidden");
    },
  },
};
</script>
