<template>
  <transition name="fade">
    <div class="endeavor-modal" v-show="expanded">
      <button
        class="endeavor-modal__close"
        aria-label="Close"
        ref="closeButton"
        @click="closeModal()"
        @keydown.esc="closeModal()"
        @keydown.tab="handleTab($event)"
      >
        <img
          src="/wp-content/themes/160over90-2021/src/images/icon/close-circled-grey.svg"
        />
      </button>

      <div class="container">
        <img :src="image.url" :alt="image.alt">
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
    }
  },
  props: {
    image: {
      type: Object
    }
  },
  methods: {
    openModal() {
      console.log('hi')
      this.expanded = true;

      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      this.$nextTick(() => {
        this.$refs.closeButton.focus();
      })
    },

    closeModal() {
      this.expanded = false;
      document.getElementsByTagName("body")[0].style.overflow = "initial";

      document.getElementById('endeavor-modal').focus()
    },

    handleTab(event) {
      event.preventDefault();
    },
  },
  mounted() {
    document.getElementById('endeavor-modal').addEventListener('click', this.openModal)
  },
  beforeDestroy() {
    document.getElementById('endeavor-modal').removeEventListener('click')
  }
}
</script>

<style>
#endeavor-modal {
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
