<template>
  <a
    class="topnav__suggestion-link"
    :href="links[currentLink]['learn_more__link']"
    v-html="currentTitle"
  >
  </a>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
    },
  },
  data() {
    return {
      currentLink: 0,
      numLinks: Number,
      currentTitle: "",
      intervalTiming: 35,
      intervalDelay: 4500,
      nextInterval: 1000,
    };
  },
  methods: {
    typeForward() {
      let fullTitle = this.links[this.currentLink]["text"];
      // strip p tags from the WYSIWYG editor
      fullTitle = fullTitle.replace("<p>", "");
      fullTitle = fullTitle.replace("</p>", "");
      this.currentTitle = "";

      let i = 0;
      let forwardInterval = setInterval(() => {
        this.currentTitle = this.currentTitle + fullTitle[i];
        i++;
        if (fullTitle[i] == "<") {
          do {
            this.currentTitle = this.currentTitle + fullTitle[i];
            i++;
          } while (fullTitle[i] != ">");
        }
        if (i == fullTitle.length) clearInterval(forwardInterval);
      }, this.intervalTiming);

      setTimeout(() => {
        this.typeBackward();
      }, this.intervalDelay);
    },

    typeBackward() {
      let backwardInterval = setInterval(() => {
        this.currentTitle = this.currentTitle.slice(0, -1);
        if (this.currentTitle[this.currentTitle.length - 1] == ">") {
          do {
            this.currentTitle = this.currentTitle.slice(0, -1);
          } while (this.currentTitle[this.currentTitle.length - 1] != "<");
          this.currentTitle = this.currentTitle.slice(0, -1);
        }

        if (this.currentTitle === "") {
          if (++this.currentLink == this.numLinks) this.currentLink = 0;
          setTimeout(() => {
            this.typeForward();
          }, this.nextInterval);
          clearInterval(backwardInterval);
        }
      }, this.intervalTiming);
    },
  },
  mounted() {
    this.numLinks = this.links.length;
    setTimeout(() => {
      this.typeForward();
    }, 750);
  },
};
</script>
