<template>
  <transition name="fade">
    <div class="gallery-modal" v-if="active">
      <button
        class="gallery-modal__close"
        aria-label="Close gallery"
        ref="closeButton"
        @click="closeModal()"
        @keydown.esc="closeModal()"
        @keydown.tab="handleTab($event)"
      >
        <img
          src="/wp-content/themes/160over90-2021/src/images/icon/close-circled-grey.svg"
        />
      </button>

      <div class="gallery-modal__list">
        <div
          class="gallery-modal__row"
          v-for="(row, index) in data"
          :key="index"
        >
          <img :src="row.image_1.url" :alt="row.image_1.alt" />
          <img
            v-if="row.image_2"
            :src="row.image_2.url"
            :alt="row.image_2.alt"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventBus from "@/js/util/eventBus";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default {
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      active: false,
      opened: {},
    };
  },
  methods: {
    openModal(loc) {
      this.active = true;
      this.opened = loc;
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      this.$nextTick(() => {
        this.$refs.closeButton.focus();

        let target = document
          .getElementsByClassName("gallery-modal__row")
          [this.opened.row].getElementsByTagName("img")[this.opened.image];
        let offset = (window.innerHeight - target.height) / 2;
        this.$el.scrollTop = target.offsetTop - offset;
      });
    },

    closeModal() {
      this.active = false;
      document.getElementsByTagName("body")[0].style.overflow = "initial";

      document
        .getElementsByClassName("work-gallery__row")
        [this.opened.row].getElementsByTagName("img")
        [this.opened.image].focus();
    },

    handleTab(event) {
      event.preventDefault();
    },
  },
  mounted() {
    eventBus.$on("galleryModal:open", this.openModal);
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
