<template>
  <article class="card-carousel__card">
    <div class="card-carousel__inner">
      <div class="card-carousel__card-top">
        <slot></slot>
      </div>

      <transition name="fade">
        <div class="card-carousel__card-bottom" v-if="flipped">
          <slot name="bottom"></slot>
        </div>
      </transition>

      <div class="card-carousel__card-button-wrap">
        <button class="card-carousel__card-button" @click="clickFlip()" aria-label="Flip card">
          <span></span>
          <transition name="fade">
            <span v-if="!flipped"></span>
          </transition>
        </button>
      </div>
    </div>
  </article>
</template>

<script>
import eventBus from "@/js/util/eventBus";

export default {
  data() {
    return {
      flipped: false,
    }
  },
  props: {
    index: {
      type: Number,
    }
  },
  methods: {
    clickFlip() {
      this.flipped = !this.flipped
      if (this.flipped) eventBus.$emit('card:flipped', this)
    },
    flipOff(el) {
      if (el != this) this.flipped = false
    }
  },
  mounted() {
    eventBus.$on('card:flipped', this.flipOff)
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
