<template>
  <VueSlickCarousel v-bind="settings" ref="carousel" class="billboard__carousel">
    <slot></slot>
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  components: {
    VueSlickCarousel
  },

  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        centerMode: true,
        infinite: false,
        focusOnSelect: true,
        variableWidth: true,
        swipeToSlide: true,
        edgeFriction: 0.75,
        lazyLoad: "ondemand",
      }
    }
  },

  methods: {
    updateTabbable() {
      Array.from(this.$el.getElementsByClassName('slick-slide')).forEach(
        element => {
          if (!element.classList.contains('slick-active')) {
            Array.from(element.getElementsByTagName('a')).forEach(link => {
              link.setAttribute('tabindex', '-1')
            })
          } else {
            Array.from(element.getElementsByTagName('a')).forEach(link => {
              link.setAttribute('tabindex', '0')
            })
          }
        }
      )
    },
  },

  mounted() {
    this.updateTabbable()
  }
}
</script>
