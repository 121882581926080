<template>
  <nav>
    <ul>
      <li v-for="(link, index) in nav" :key="index">
        <a href="#"
          v-html="link"
          :class="{ active : active == link }"
          @click="toggleActive(link, $event)"></a>
      </li>
    </ul>

    <slot></slot>
  </nav>
</template>

<script>
import eventBus from "@/js/util/eventBus"

export default {
  props: {
    nav: {
      type: Array,
    }
  },
  data() {
    return {
      active: "",
    }
  },
  methods: {
    toggleActive(link, event) {
      event.preventDefault()
      this.active = link
      eventBus.$emit('toggle:filter', this.active)
    }
  },
  mounted() {
    this.active = this.nav[0];
  }
}
</script>
