/*
|--------------------------------------------------------------------------
| Entry File
|--------------------------------------------------------------------------
|
| This is the main entry file used by Webpack. As such, we use it to
| include CSS as well as the usual JS. The order is important as
| CSS rules ar affected by specificity. All styles loaded in
| App.vue and its child files will of course be included.
|
*/

// Styles
import '@/styles/main.scss'

// Vue
import Vue from 'vue'
// import App from './App.vue'

// Import components
import ExampleComponent from '@/js/components/ExampleComponent.vue'
import HelloWorld from '@/js/components/HelloWorld.vue'
import DarkModeSwitch from '@/js/components/DarkModeSwitch.vue'
import GlobalAnimations from '@/js/components/GlobalAnimations.vue'
import LocationScroll from '@/js/components/LocationScroll.vue'
import WorkGallery from '@/js/components/WorkGallery.vue'
import WorkGalleryModal from '@/js/components/WorkGalleryModal.vue'
import BillboardCarousel from '@/js/components/BillboardCarousel.vue'
import Accordion from '@/js/components/Accordion.vue'
import WorkFilter from '@/js/components/WorkFilter.vue'
import WorkFilterNav from '@/js/components/WorkFilterNav.vue'
import CardCarousel from '@/js/components/CardCarousel.vue'
import CardFlip from '@/js/components/CardFlip.vue'
import EndeavorModal from '@/js/components/EndeavorModal.vue'

// Top nav
import NavButton from '@/js/components/top-nav/NavButton.vue'
import NavSticky from '@/js/components/top-nav/NavSticky.vue'
import NavExpanded from '@/js/components/top-nav/NavExpanded.vue'
import NavLearnMore from '@/js/components/top-nav/NavLearnMore.vue'

import ModalVideo from '@/js/components/modal-video/index'
import HsForm from '@/js/components/HsForm'

const Components = {
  ExampleComponent,
  HelloWorld,
  DarkModeSwitch,
  GlobalAnimations,
  HsForm,
  LocationScroll,
  WorkGallery,
  WorkGalleryModal,
  BillboardCarousel,
  Accordion,
  CardCarousel,
  CardFlip,
  EndeavorModal,
  WorkFilter,
  WorkFilterNav,
  NavButton,
  NavSticky,
  NavExpanded,
  NavLearnMore
}

Vue.use(ModalVideo)

Object.keys(Components).forEach(componentName => {
  Vue.component(componentName, Components[componentName])
})
Vue.config.productionTip = false

new Vue().$mount('#app')
