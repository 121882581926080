<template>
  <transition name="nav" v-cloak>
    <nav v-if="expanded" :class="{ loaded: loaded }">
      <slot></slot>
    </nav>
  </transition>
</template>

<script>
import eventBus from "@/js/util/eventBus";
export default {
  data() {
    return {
      expanded: false,
      loaded: false,
    };
  },
  methods: {
    toggleExpanded(expanded) {
      this.expanded = expanded;

      if (this.expanded) {
        eventBus.$emit("nav:noscroll", false);
      } else {
        eventBus.$emit("nav:noscroll", true);
      }
    },
  },
  mounted() {
    eventBus.$on("topnav:toggle", this.toggleExpanded);

    this.$nextTick(() => {
      this.loaded = true;
    })
  },
};
</script>

<style lang="scss" scoped>
.nav-enter-active,
.nav-leave-active {
  transition: all 0.25s ease-in;
}
.nav-enter, .nav-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-80px);
}
</style>
