<template>
  <div aria-live="polite">
    <slide-up-down :active="expanded" :duration="250">
      <slot></slot>
    </slide-up-down>

    <button @click="expanded = !expanded" class="button button--small button--white-grey-border" :class="{ expanded : expanded }" :aria-expanded="expanded.toString()">
      <img src="/wp-content/themes/160over90-2021/src/images/svgs/caret_down.svg" alt="">
      <span v-html="expanded ? 'View Less' : 'View More'"></span>
    </button>
  </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down';

export default {
  components: {
    SlideUpDown
  },
  data() {
    return {
      expanded: false,
    }
  }
}
</script>
